import { Body, HorizontalSpacer, VerticalSpacer } from '@superhi/design'
import { EventHandler, KeyboardEvent } from 'react'

import { Label } from './styles'

type Option<T> = {
  value: T
  display: string
}

type Props<T> = {
  id: string
  options: Option<T>[]
  value?: Option<T>['value']
  onChange: (value: Option<T>['value']) => void
}

function RadioOptions<T extends string>({ id, value, options, onChange }: Props<T>) {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLLabelElement>, value: T) => {
    e.persist()
    if (e.currentTarget === document.activeElement) {
      onChange(value)
    }
  }

  return (
    <VerticalSpacer amount={{ small: 4, medium: 4, large: 4 }}>
      {options.map((option) => (
        <div key={option.value}>
          <Label
            htmlFor={`${id}-${option.value}`}
            tabIndex={0}
            onKeyPress={(e) => handleKeyPress(e, option.value)}
          >
            <input
              id={`${id}-${option.value}`}
              type="radio"
              value={option.value}
              checked={option.value === value}
              onChange={() => onChange(option.value)}
              tabIndex={-1}
            />{' '}
            <Body level="3">{option.display}</Body>
          </Label>
        </div>
      ))}
    </VerticalSpacer>
  )
}

export default RadioOptions
