import styled from 'styled-components'

export const Label = styled.label`
  display: inline-flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`
