import { useQuery } from '@apollo/client'
import { Body, Button, Lockup, Price, VerticalSpacer } from '@superhi/design'
import { formatDateOnly } from '@superhi/design/dist/cjs/components/utils/DateTime'

import { API } from '../../../../../../api'
import { Section } from '../../../../../../components/PageLayout'
import { INTERVAL } from '../../../../utils'
import UpdatePlan from '../../../UpdatePlan'

import { PLAN } from './queries'

const PlanDetails = () => {
  const { data, loading } = useQuery<API.Membership_PlanDetails>(PLAN)

  const plan = data?.session?.membership?.plan
  const currentPlanIntervalLy =
    plan?.currentPrice.recurring && INTERVAL(true)[plan.currentPrice.recurring.interval]
  const switchingText = plan?.nextPrice?.recurring &&
    plan.nextPrice.recurring.interval !== plan.currentPrice?.recurring?.interval && (
      <Body level="3" color="GREY_70">
        You are switching to <strong>{INTERVAL(true)[plan.nextPrice.recurring.interval]}</strong>{' '}
        billing on <strong>{formatDateOnly(plan.renewalDate)}</strong>
      </Body>
    )
  const endingText = plan?.renewalDate && !plan?.nextPayment && (
    <Body level="3">
      Your membership is ending on <strong>{formatDateOnly(plan.renewalDate)}</strong>
    </Body>
  )

  return (
    <Section
      title="Your plan"
      loading={loading}
      buttons={
        <Button version="outline" href={UpdatePlan.route.path()}>
          Update plan
        </Button>
      }
    >
      {plan ? (
        <VerticalSpacer amount={{ small: 4, medium: 4, large: 4 }}>
          <Body level="3">
            <strong>
              {plan.product.title}
              {currentPlanIntervalLy && <> ({currentPlanIntervalLy})</>} &mdash;{' '}
              <Price currencyCode={plan.currentPrice.currencyCode}>{plan.currentPrice.value}</Price>
            </strong>
            <br />
            {plan?.nextPayment && (
              <>
                Next payment:{' '}
                <strong>
                  {Price.format(plan.nextPayment.value, plan.nextPayment.currencyCode)}
                </strong>{' '}
                on <strong>{formatDateOnly(plan.renewalDate)}</strong>
              </>
            )}
          </Body>

          {switchingText}

          {endingText}
        </VerticalSpacer>
      ) : (
        <Body level="3" color="GREY_70">
          You don&apos;t have an active membership.
        </Body>
      )}
    </Section>
  )
}

export default PlanDetails
